import { useLazyQuery } from "@apollo/client";
import { useOnErrorGraphql } from "@newrai/ant-design";
import { clientCentralApi } from "@newrai/api-clients";
import { routers } from "config/routes";
import useStore from "config/store";
import { GET_LOGO_ACCOUNTING_FIRM } from "queries/centralapi/accountingFirms";
import { GET_FIRST_COMPANY } from "queries/centralapi/general";
import { useState } from "react";
import newRaiLogo from "resources/images/logo-blue.png";
import { getImage } from "utils/downloadFile";

interface useLazyQueryGetLogoAccountingFirmResponse {
  setLogo: (value: ((prevState: string) => string) | string) => void;
  getLogo: (options?: any) => any;
  data: any;
  logo: string;
  refetch: (variables?: any) => any;
}

interface useLazyQueryListCompaniesResponse {
  data: any;
  refetch: (variables?: any) => any;
  listCompanies: (options?: any) => any;
}

export function useLazyQueryGetLogoAccountingFirm(): useLazyQueryGetLogoAccountingFirmResponse {
  const [logo, setLogo] = useState<string>("");
  const { onErrorGraphql } = useOnErrorGraphql();
  const [getLogo, { data, refetch }] = useLazyQuery<GetAccountingFirmsOptionsOutput>(
    GET_LOGO_ACCOUNTING_FIRM,
    {
      client: clientCentralApi,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError: onErrorGraphql,
      onCompleted: async data => {
        const listAccountingFirms = data.listAccountingFirmsPaginated?.results.filter(
          item => item.logo,
        );
        listAccountingFirms.length > 0 &&
          setLogo(await getImage(listAccountingFirms[0].logo || newRaiLogo));
      },
    },
  );

  return { getLogo, logo, data, refetch, setLogo };
}

export function useLazyQueryListCompanies(): useLazyQueryListCompaniesResponse {
  const setCompanySelected = useStore(state => state.setCompanySelected);
  const { onErrorGraphql } = useOnErrorGraphql();

  const [listCompanies, { data, refetch }] = useLazyQuery(GET_FIRST_COMPANY, {
    client: clientCentralApi,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError: onErrorGraphql,
    onCompleted: data => {
      const companies = data.listCompaniesPaginated ? data.listCompaniesPaginated?.results : [];
      if (companies.length > 0) {
        const firstCompany = companies[0];
        const companySelectedData = {
          accountingFirm: firstCompany.accountingFirm,
          company: firstCompany,
        };

        setCompanySelected(companySelectedData);
        routers.navigate(`/company/${firstCompany?.id}`, { replace: true });
      }
    },
  });

  return { listCompanies, data, refetch };
}
