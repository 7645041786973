import { HeaderWithOptions } from "@newrai/ant-design";
import { clientNeoActivite, clientNeoActiviteWS, clientNeoDocs } from "@newrai/api-clients";
import axios from "axios";
import { langsCode, langsLabel } from "config/i18n/i18n";
import API, { APP_URL } from "config/settings";
import useStore from "config/store";
import i18next from "i18next";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Logo from "resources/images/neodashboard.png";
import { useGetAndSaveCompanies } from "views/Base/hooks/useGetAndSaveCompanies";
import { useQueryCompanyAccountingFirm } from "views/Base/hooks/useQueryCompanyAccountingFirm";
import { useLazyQueryListCompanies } from "views/Home/hooks/hooks";
import { routers } from "./../../../../config/routes";
import CenteredItems from "./CenteredItems";

interface HeaderProps {
  isHome: boolean;
}

function Header({ isHome }: HeaderProps) {
  const { t } = useTranslation();

  const setIsCompanySelectOpen = useStore(state => state.setIsCompanySelectOpen);
  const userData = useStore(state => state.userData);
  const companySelected = useStore(state => state.companySelected);
  const isNewrai = userData?.isNewraiUser;
  const userIsClient = ["client", "client_without_accounting"].includes(
    userData?.userCategory.slug ?? "",
  );

  const [language, setLanguage] = useState(i18next.language);

  const { companyWithAccountingFirm } = useQueryCompanyAccountingFirm();
  const { selectedCompanyOptions } = useGetAndSaveCompanies();
  const { listCompanies } = useLazyQueryListCompanies();

  const { companyId } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (userIsClient) {
      const companyToFetch =
        userData?.id && userData?.companies?.length === 1
          ? userData.companies[0]
          : companyId && !companySelected
            ? companyId
            : null;

      if (companyToFetch) {
        companyWithAccountingFirm({
          variables: {
            id: companyToFetch,
          },
        });

        if (location.pathname === `/` || companyId !== companyToFetch) {
          routers.navigate(`/company/${companyToFetch}`, { replace: true });
        }

        setIsCompanySelectOpen(false);
      } else if (userData?.companies.length > 1) {
        listCompanies();
        setIsCompanySelectOpen(true);
      }
    }
  }, []);

  const handleLogout = () => {
    axios(API.revokeToken, {
      method: "post",
      data: `token=${Cookies.get("access_token")}&client_id=${API.clientId}`,
    }).then(() => {
      window.location.href = APP_URL.neoauthUrl;
      Cookies.remove("access_token");
      Cookies.remove("expireDate");
    });
  };

  return (
    <HeaderWithOptions
      avatarOptions={{
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        goToProfile: () => routers.navigate("/account/profile"),
        onLogout: handleLogout,
      }}
      languageOptions={{
        changeLanguage: item => {
          setLanguage(item);
          i18next.changeLanguage(item);
        },
        languageCodes: langsCode,
        languageLabels: langsLabel(),
        selectedLanguage: language,
      }}
      logoOptions={{
        src: Logo,
        alt: "NeoDashboard",
      }}
      selectCompanyOptions={selectedCompanyOptions()}
      centeredItems={<CenteredItems />}
      neoactivite={
        !isHome
          ? {
              neoActiviteClientHttp: clientNeoActivite,
              neoActiviteClientWS: clientNeoActiviteWS,
              neoActiviteUrl: APP_URL.neoActiviteUrl,
            }
          : undefined
      }
      freshDeskWidget={{
        autofill: {
          requester: userData?.email,
          name: userData?.firstName,
          subject: companySelected?.company?.id
            ? `${companySelected?.accountingFirm?.name} - ${companySelected?.company?.internalReference}: ${companySelected?.company?.name}
          `
            : "",
        },
        formTitle: t("Client support"),
        isNewrai: isNewrai,
      }}
      showModalATC={{
        neoDocsClientHttp: clientNeoDocs,
        neoActiviteClientWS: clientNeoActiviteWS,
        neoscanExpertUrl: APP_URL.neoScanExpertUrl,
      }}
      isATC={false}
    />
  );
}

export default React.memo(Header);
