import { useLazyQuery } from "@apollo/client";
import { useOnErrorGraphql } from "@newrai/ant-design";
import { clientCentralApi } from "@newrai/api-clients";
import useStore from "config/store";
import { GET_COMPANY_WITH_ACCOUNTING_FIRM } from "queries/centralapi/companies";

export function useQueryCompanyAccountingFirm() {
  const { onErrorGraphql } = useOnErrorGraphql();
  const setCompanySelected = useStore(state => state.setCompanySelected);

  const [companyWithAccountingFirm, { data }] = useLazyQuery<
    CompanyWithAccountingFirmOutput,
    CompanyWithAccountingFirmOnput
  >(GET_COMPANY_WITH_ACCOUNTING_FIRM, {
    client: clientCentralApi,
    onError: onErrorGraphql,
    onCompleted: ({ getCompany }) => {
      if (getCompany) {
        const { accountingFirm, ...company } = getCompany;

        setCompanySelected({
          accountingFirm,
          company,
        });
      }
    },
  });

  return {
    companyWithAccountingFirm,
    data,
  };
}
