import { createStore, setStateHelper } from "@newrai/ant-design";
import { StoreApi, UseBoundStore } from "zustand";

interface UserStore extends GetUserByToken {
  groupDefault: { order: number };
}

interface StoreState {
  userData: UserStore;
  setUserData: (userData: UserStore) => void;
  companySelected: CompanySelected;
  setCompanySelected: (companySelected?: CompanySelected | undefined) => void;
  isCompanySelectOpen: boolean;
  setIsCompanySelectOpen: (isCompanySelectOpen: boolean) => void;
  companySoftware: SoftwareType;
  setCompanySoftware: (companySoftware: SoftwareType) => void;
  createUpdateUserLoading: boolean;
  setCreateUpdateUserLoading: (createUpdateUserLoading: boolean) => void;
  dataChanged: boolean;
  setDataChanged: (dataChanged: boolean) => void;
  saveButtonWasClicked: boolean;
  setSaveButtonWasClicked: (saveButtonWasClicked: boolean) => void;
  externalServiceData: OptionType[];
  setExternalServiceData: (externalServiceData: OptionType) => void;
  currentCycle: CycleType;
  setCurrentCycle: (currentCycle: CycleType) => void;
}

const initialState = {
  userData: undefined,
  companySelected: undefined,
  isCompanySelectOpen: false,
  companySoftware: undefined,
  createUpdateUserLoading: undefined,
  dataChanged: false,
  saveButtonWasClicked: false,
  externalServiceData: undefined,
  currentCycle: undefined,
};

const useStore: UseBoundStore<StoreApi<StoreState>> = createStore<StoreState>(
  (set: (fn: (draft: StoreState) => void) => void, get: any) => ({
    ...initialState,
    resetStore: () => {
      set(() => ({
        ...initialState,
      }));
    },
    setUserData: (userData: UserStore) => {
      setStateHelper("userData", userData, set, get);
    },
    setCompanySoftware: (companySoftware: SoftwareType) => {
      setStateHelper("companySoftware", companySoftware, set, get);
    },
    setCurrentCycle: (currentCycle: CycleType) => {
      setStateHelper("currentCycle", currentCycle, set, get);
    },
    setCompanySelected: (companySelected: CompanySelected) => {
      setStateHelper("companySelected", companySelected, set, get);
    },
    setIsCompanySelectOpen: (isCompanySelectOpen: boolean) => {
      setStateHelper("isCompanySelectOpen", isCompanySelectOpen, set, get);
    },
    setCreateUpdateUserLoading: (createUpdateUserLoading: boolean) => {
      setStateHelper("createUpdateUserLoading", createUpdateUserLoading, set, get);
    },
    setDataChanged: (dataChanged: boolean) => {
      setStateHelper("dataChanged", dataChanged, set, get);
    },
    setExternalServiceData: (externalServiceData: OptionType[]) => {
      setStateHelper("externalServiceData", externalServiceData, set, get);
    },
    setSaveButtonWasClicked: (saveButtonWasClicked: boolean) => {
      setStateHelper("saveButtonWasClicked", saveButtonWasClicked, set, get);
    },
  }),
);

export default useStore;
