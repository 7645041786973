import { clientCentralApi } from "@newrai/api-clients";
import { routers } from "config/routes";
import useStore from "config/store";
import useCompanyPermissions from "hooks/Permissions/useCompanyPermissions";
import { GET_LIST_COMPANIES } from "queries/centralapi/general";
import { useTranslation } from "react-i18next";
import { createAccountingFirmOption, createCompanyOption } from "utils/selectOptions";

const SEARCH_LIMIT = 50;

export function useGetAndSaveCompanies() {
  const { t } = useTranslation();
  const { canViewCompaniesList } = useCompanyPermissions();
  const setIsCompanySelectOpen = useStore(state => state.setIsCompanySelectOpen);
  const isCompanySelectOpen = useStore(state => state.isCompanySelectOpen);
  const setCompanySelected = useStore(state => state.setCompanySelected);
  const companySelect = useStore(state => state.companySelected);
  const userData = useStore(state => state.userData);

  /**
   * Function to get options list of companies from
   * listCompaniesPaginated query
   */
  const loadCompaniesOptions = async (search = "", prevOptions: Record<string, any>[]) => {
    let hasMore = false;
    const variables = {
      limit: SEARCH_LIMIT,
      offset: prevOptions.length,
      filter: {
        nameOrInternalReference: search,
      },
    };

    const response = await getMoreData({
      query: GET_LIST_COMPANIES,
      dataName: "listCompaniesPaginated",
      variables,
    });
    const resultsList = response?.results;
    const options = resultsList.map((companyItem: Record<string, any>) =>
      createCompanyOption(companyItem),
    );

    hasMore = resultsList.length < response?.totalCount;
    return { options, hasMore };
  };

  /**
   * Function to get data list to async select
   */
  const getMoreData = ({
    query,
    dataName,
    variables,
  }: {
    query: any;
    dataName: string;
    variables: Record<string, any>;
  }) => {
    return clientCentralApi
      .query({
        query,
        variables,
      })
      .then(({ data }: { data: Record<string, any> }) => {
        return data[dataName];
      });
  };

  const onSaveCompany = (company: Record<string, any>) => {
    setCompanySelected({
      accountingFirm: company.accountingFirm,
      company: {
        id: company.id,
        name: company.name,
        internalReference: company.internalReference,
        accountingFirm: company.accountingFirm,
      },
    });

    routers.navigate(`/company/${company.id}`);

    setIsCompanySelectOpen(false);
  };

  const hasMoreCompanies =
    userData?.companies.length > 1 &&
    ["client"].includes(userData?.userCategory?.slug) &&
    canViewCompaniesList;

  const selectedCompanyOptions = () => {
    if (["client"].includes(userData?.userCategory?.slug) && canViewCompaniesList) {
      return {
        accountingFirmValue: companySelect?.accountingFirm,
        companyValue: companySelect?.company,
        isModalOpen: isCompanySelectOpen,
        loadAccountingFirmsOptions: undefined,
        loadCompaniesOptions: loadCompaniesOptions,
        onSave: onSaveCompany,
        selectedAccountingFirmsOptionFormat: createAccountingFirmOption,
        selectedCompanyOptionFormat: createCompanyOption,
        companyInputOnly: true,
        maskClosable: !companySelect,
        blockOnCancel: !!companySelect,
        isEditable: true,
        translations: {
          title: {
            dialog: t("Select company"),
            accompanyFirmDropdown: t("Accounting Firm"),
            companyDropdown: t("Company"),
          },
          button: {
            close: t("Close"),
            save: t("Submit"),
          },
          loadingMessage: `${t("Loading")}...`,
          noOptionsMessage: t("No options"),
          placeholder: `${t("Select")}...`,
        },
      };
    }
  };

  return { loadCompaniesOptions, hasMoreCompanies, selectedCompanyOptions };
}
