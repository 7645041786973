import { CI_COMMIT_SHORT_SHA } from "config/settings";
import { clientNeoAuth } from "@newrai/api-clients";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import App from "./App";
import { hotjar } from "react-hotjar";

if (process.env.NODE_ENV === "production" && process.env.VITE_HOTJAR) {
  hotjar.initialize({
    id: Number(process.env.VITE_HOTJAR),
    sv: 6,
  });
}

if (process.env.VITE_ENV !== "local") {
  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    environment: process.env.VITE_ENV,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "TypeError: NetworkError when attempting to fetch resource.",
      "Failed to fetch",
      "Invalid Token",
    ],
    release: CI_COMMIT_SHORT_SHA,
  });
  Sentry.setTag("CI_COMMIT_SHORT_SHA", CI_COMMIT_SHORT_SHA);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ApolloProvider client={clientNeoAuth}>
    <App />
  </ApolloProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
