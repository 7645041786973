import { gql } from "@apollo/client";

export const GET_LIST_ACCOUNTING_FIRMS_QUERY = gql`
  query ($limit: Int, $offset: Int, $orderBy: [String], $filter: AccountingFirmFilterInput) {
    listAccountingFirmsPaginated(filter: $filter) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        vatNumber
        name
        emails
        phones
        companies {
          id
        }
        settings {
          defaultSsiType
        }
      }
    }
  }
`;

export const LIST_ACCOUNTING_FIRMS_SELECT_QUERY = gql`
  query ($limit: Int, $offset: Int, $orderBy: [String], $filter: AccountingFirmFilterInput) {
    listAccountingFirmsPaginated(filter: $filter) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        name
        settings {
          defaultSsiType
          accountingPlanType {
            value
          }
        }
      }
      totalCount
    }
  }
`;

export const LIST_ACCOUNTING_FIRMS_SELECT_QUERY_WITH_FILTER = gql`
  query ($limit: Int, $offset: Int, $orderBy: [String], $search: String) {
    listAccountingFirmsPaginated(filter: { name: $search }) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        name
        settings {
          defaultSsiType
        }
      }
      totalCount
    }
  }
`;

export const GET_ACCOUNTING_FIRMS_BY_ID_QUERY = gql`
  query ($id: ID!) {
    getAccountingFirm(id: $id) {
      id
      name
      shortName
      businessName
      website
      emails
      phones
      fax
      vatNumber
      logo
      companies {
        id
      }
      settings {
        defaultSsiType
        language
        defaultNotificationEmail
      }
      addresses {
        id
        addressType
        address1
        address2
        address3
        addressBtq
        addressNr
        postalCode
        city
        country {
          code
          name
        }
      }
    }
    listPlatforms(filter: { accountingFirm: $id }) {
      results(limit: 100, offset: 0) {
        id
        name
        software
        remoteAccountingFirmId
        apiUrl
      }
    }
  }
`;

export const GET_SETTINGS_ACCOUNTING_FIRMS = gql`
  query getAccountingFirmSettings($id: ID!) {
    getAccountingFirmSettings(id: $id) {
      defaultSsiType
      language
      defaultNotificationEmail
      nameGeneratorChoices
      nameGeneratorSeparator
      nameGeneratorDateFormat
      halfDoesNeoscan
      halfNeossiReportsPeriod
      emailFilesAllowedEmails
      serializeDocumentsInExport
      accountingPlanType {
        label
        value
      }
    }
  }
`;

export const GET_OPTIONS_ACCOUNTING_FIRM = gql`
  query {
    ssiType: __type(name: "AccountingFirmSettingDefaultSsiType") {
      enumValues {
        name
      }
    }
    language: __type(name: "AccountingFirmSettingLanguage") {
      enumValues {
        name
      }
    }
    options: __type(name: "NameGeneratorChoicesEnum") {
      enumValues {
        name
        description
      }
    }
    characters: __type(name: "NameGeneratorSeparatorsEnum") {
      enumValues {
        name
        description
      }
    }
    dateOption: __type(name: "DataFormatEnum") {
      enumValues {
        name
        description
      }
    }
    neoscanEnum: __type(name: "DoesNeoscanEnum") {
      enumValues {
        name
        description
      }
    }
    halfNeossiReportsPeriod: __type(name: "HalfReportPeriodChoisesEnum") {
      enumValues {
        name
        description
      }
    }
    listAccountingPlanTypeChoices {
      label
      value
    }
  }
`;

export const UPDATE_ACCOUNTING_FIRM = gql`
  mutation updateAccountingFirm(
    $name: String!
    $shortName: String
    $businessName: String
    $id: ID!
    $emails: [String]!
    $website: String
    $phones: [String]!
    $fax: String
    $vatNumber: String!
    $updateLogo: Upload
  ) {
    updateAccountingFirm(
      input: {
        name: $name
        shortName: $shortName
        businessName: $businessName
        id: $id
        emails: $emails
        website: $website
        phones: $phones
        fax: $fax
        vatNumber: $vatNumber
        logo: $updateLogo
      }
    ) {
      accountingFirm {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_ACCOUNTING_FIRM_SETTINGS = gql`
  mutation updateAccountingFirmSettings($input: AccountingFirmSettingInput!) {
    updateAccountingFirmSettings(input: $input) {
      accountingFirmSettings {
        defaultSsiType
        language
        defaultNotificationEmail
        nameGeneratorDateFormat
        accountingPlanType {
          label
          value
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const ADD_ACCOUNTING_FIRM = gql`
  mutation createAccountingFirm(
    $name: String!
    $shortName: String
    $businessName: String
    $emails: [String]!
    $phones: [String]!
    $vatNumber: String!
  ) {
    createAccountingFirm(
      input: {
        name: $name
        shortName: $shortName
        businessName: $businessName
        emails: $emails
        phones: $phones
        vatNumber: $vatNumber
      }
    ) {
      accountingFirm {
        id
        name
        shortName
        businessName
        emails
        phones
        vatNumber
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_LOGO_ACCOUNTING_FIRM = gql`
  query ($ids: [ID], $limit: Int) {
    listAccountingFirmsPaginated(filter: { id_In: $ids }) {
      totalCount
      results(limit: $limit, offset: 0) {
        id
        name
        logo
      }
    }
  }
`;

export const GET_PLATFORMS_LIST = gql`
  query ($accountingFirmID: ID) {
    listPlatforms(filter: { accountingFirm: $accountingFirmID }) {
      results(limit: 100, offset: 0) {
        id
        name
        software
      }
    }
  }
`;

export const LIST_ACCOUNTING_FIRMS_QUERY = gql`
  query listAccountingFirmsPaginated(
    $orderBy: [String]
    $search: String
    $limit: Int
    $offset: Int
    $companies_Settings_SsiType_In: [String]
    $deletedAtIsnull: Boolean
  ) {
    listAccountingFirmsPaginated(
      filter: {
        name: $search
        companies_Settings_SsiType_In: $companies_Settings_SsiType_In
        deletedAt_Isnull: $deletedAtIsnull
      }
    ) {
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        name
      }
      totalCount
    }
  }
`;
